import * as React from 'react';
import MigrationIcon from '../../images/services-data-migration.svg'
import DevopsIcon from '../../images/services-devops.svg'
import TrainingIcon from '../../images/services-training.svg'
import IntegrationIcon from '../../images/services-integration.svg'
import styled from 'styled-components';


const ICON_SIZE = 50;
const StyledMigrationIcon = styled(MigrationIcon)`
  fill: ${(props) => props.theme.primary};
  height: ${ICON_SIZE}px;
  width: ${ICON_SIZE}px;
`;
const StyledTrainingIcon = styled(TrainingIcon)`
  fill: ${(props) => props.theme.primary};
  height: ${ICON_SIZE}px;
  width: ${ICON_SIZE}px;
`;
const StyledIntegrationIcon = styled(IntegrationIcon)`
  fill: ${(props) => props.theme.primary};
  height: ${ICON_SIZE}px;
  width: ${ICON_SIZE}px;
`;
const StyledDevopsIcon = styled(DevopsIcon)`
  fill: ${(props) => props.theme.primary};
  height: ${ICON_SIZE}px;
  width: ${ICON_SIZE}px;
`;

const Icon = ({name}) => {
    switch(name) {
        case 'migration':
            return (<StyledMigrationIcon/>);
        case 'training':
            return (<StyledTrainingIcon/>);
        case 'integration':
            return (<StyledIntegrationIcon/>);
        case 'devops':
            return (<StyledDevopsIcon/>);
        default:
            return (<StyledMigrationIcon/>);
    }
}

export default Icon;